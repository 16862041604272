import gsap from "gsap";
import React, { useRef } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import tinycolor from "tinycolor2";

const OnclickButton = ({
	colour,
	text,
	isOutline,
	width,
	outlineTextColor,
	textColor,
	onClick,
	type = "button", // Default type is "button", but can be overridden to "submit"
	disabled = false, // Default is false
}) => {
	const buttonRef = useRef(null);
	const buttonWidth = width || "w-auto";

	// Determine the default outline text color (can be overridden by `outlineTextColor`)
	const defaultOutlineTextColor = outlineTextColor || "#00304B";

	// Determine the default text color for non-outline buttons (can be overridden by `textColor`)
	const defaultTextColor = textColor || "#fff";

	// Determine hover text color for outline buttons
	const hoverTextColor =
		colour === "#fff" || colour.toLowerCase() === "white" ? "#000" : "#fff";

	const handleMouseEnter = () => {
		if (!disabled) {
			const lighterColor = tinycolor(colour).lighten(10).toString(); // Lighten by 10%
			gsap.to(buttonRef.current, {
				backgroundColor: isOutline ? colour : lighterColor,
				color: isOutline ? hoverTextColor : defaultTextColor, // Only change text color for outline buttons
				duration: 0.1,
			});
		}
	};

	const handleMouseLeave = () => {
		if (!disabled) {
			gsap.to(buttonRef.current, {
				backgroundColor: isOutline ? "transparent" : colour,
				color: isOutline ? defaultOutlineTextColor : defaultTextColor,
				duration: 0.1,
			});
		}
	};

	const buttonStyle = {
		borderRadius: "0px",
		backgroundColor: disabled
			? "#e0e0e0" // Greyed out background for disabled buttons
			: isOutline
			? "transparent"
			: colour,
		color: disabled
			? "#a0a0a0" // Greyed out text for disabled buttons
			: isOutline
			? defaultOutlineTextColor
			: defaultTextColor,
		borderColor: disabled ? "#e0e0e0" : colour,
		cursor: disabled ? "not-allowed" : "pointer",
		transition: "background-color 0.1s, color 0.1s", // Fallback for non-GSAP users
	};

	return (
		<BootstrapButton
			type={type} // Dynamically set the button type
			className={`py-2 px-4 ${buttonWidth}`}
			ref={buttonRef}
			style={buttonStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={!disabled ? onClick : undefined} // Prevent click if disabled
			disabled={disabled} // Disable the button
		>
			{text}
		</BootstrapButton>
	);
};

export default OnclickButton;
