import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TranslateComponent from "./TranslateComponent";

const ClientSelectBanner = ({
	clientName,
	languageCode,
	clients,
	selectedClientIndex,
	handleClientChange,
}) => {
	return (
		<div
			style={{
				backgroundColor: "#00304B",
				padding: "1rem 0",
				borderBottom: "1px solid #00304B",
			}}
		>
			<Container>
				<Row className="align-items-center ">
					<Col xs={6} md={9}>
						<p className="text-white  py-0 my-0">
							<strong>
								<TranslateComponent
									code={languageCode}
									text="Currently Viewing Data For:"
								/>
							</strong>{" "}
						</p>
					</Col>
					<Col xs={6} md={3} className="text-end mt-2 mt-md-0">
						<div>
							<select
								className="form-select"
								value={selectedClientIndex}
								onChange={handleClientChange}
							>
								{clients.map((client, index) => (
									<option key={index} value={index}>
										{client.acf.company_name}
									</option>
								))}
							</select>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ClientSelectBanner;
