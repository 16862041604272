import React, { useEffect, useState } from "react";
import CardLayoutSection from "../../components/CardLayoutSection";
import ClientLayout from "../../components/client-Layout";
import ClientSelectBanner from "../../components/ClientSelectBanner";
import HubHero from "../../components/HubHero";
import SplashScreen from "../../components/SplashScreen";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import WelcomeSection from "../../components/WelcomeSection";
import withAuth from "../../components/withAuth";

const ClientAdminDashboard = () => {
	const [clientData, setClientData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [selectedClientIndex, setSelectedClientIndex] = useState(0); // Index of the selected client

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const handleClientChange = (event) => {
		setSelectedClientIndex(parseInt(event.target.value, 10));
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const data = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, data.message);
				return null;
			}

			return data;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const data = await fetchData("client?_fields=acf&acf_format=standard");
			if (data) {
				setClientData(data);
			} else {
				setClientData(null);
			}
		};

		getData();
	}, []);

	if (!clientData) {
		return <SplashScreen />;
	}

	const selectedClient = clientData[selectedClientIndex]?.acf;

	return (
		<ClientLayout
			navLinks={selectedClient?.navbar_links}
			languageCode={languageCode}
		>
			<div>
				<ClientSelectBanner
					handleClientChange={handleClientChange}
					selectedClientIndex={selectedClientIndex}
					languageCode={languageCode}
					clients={clientData}
				/>

				<div className="d-md-none">
					<TranslationBanner
						languageCode={languageCode}
						onSelectLanguage={handleLanguageChange}
					/>
				</div>
				<HubHero
					customerName={selectedClient?.company_name}
					afterText={<TranslateComponent code={languageCode} text={"hub"} />}
					heading={
						<TranslateComponent
							code={languageCode}
							text={selectedClient?.hero_section?.heading}
						/>
					}
					body={
						selectedClient?.hero_section?.body.length > 1 ? (
							<TranslateComponent
								code={languageCode}
								text={selectedClient?.hero_section?.body || ""}
							/>
						) : (
							""
						)
					}
					image={selectedClient?.hero_section?.image}
				/>

				<div className="d-none d-md-block">
					<TranslationBanner
						languageCode={languageCode}
						onSelectLanguage={handleLanguageChange}
					/>
				</div>

				{/* Card Layout Section */}
				{selectedClient?.card_layout && (
					<CardLayoutSection
						logo={selectedClient?.company_logo}
						languageCode={languageCode}
						heading={selectedClient?.card_layout.heading}
						body={selectedClient?.card_layout.body}
						boldText={selectedClient?.card_layout.bold_text}
						cards={selectedClient?.card_layout.cards}
					/>
				)}

				{/* Welcome Section */}
				<WelcomeSection
					languageCode={languageCode}
					tagline={selectedClient?.welcome_banner?.tagline}
					name={selectedClient?.welcome_banner?.name}
					body={selectedClient?.welcome_banner?.body}
					background_image={selectedClient?.welcome_banner?.background_image}
				/>
			</div>
		</ClientLayout>
	);
};

export default withAuth(ClientAdminDashboard, "client_admin");
