import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BGImage from "../images/background-logo.svg";
import OnclickButton from "./OnclickButton";
import TranslateComponent from "./TranslateComponent";

const CardLayoutSection = ({
	languageCode,
	heading,
	body,
	boldText,
	cards,
	logo,
}) => {
	// Initially show only 6 cards
	const [visibleCount, setVisibleCount] = useState(6);

	// Determine how many cards to render
	const visibleCards = cards ? cards.slice(0, visibleCount) : [];

	const handleViewAll = () => {
		if (cards) {
			setVisibleCount(cards.length);
		}
	};

	const hexToRgba = (hex, opacity) => {
		// Remove the '#' if present
		const cleanHex = hex.replace("#", "");

		// Parse the hex into RGB values
		const r = parseInt(cleanHex.substring(0, 2), 16);
		const g = parseInt(cleanHex.substring(2, 4), 16);
		const b = parseInt(cleanHex.substring(4, 6), 16);

		return `rgba(${r}, ${g}, ${b}, ${opacity})`;
	};

	const showViewAllButton = cards && cards.length > visibleCount;

	return (
		<section className="py-5 py-lg-7 card-layout-section position-relative">
			<Container>
				<Row>
					<Col xl={10}>
						<Row className="mb-4 align-items-center">
							<Col lg={6}>
								<h2 className="mb-3 fs-1" style={{ color: "#73B43E" }}>
									<TranslateComponent code={languageCode} text={heading} />
								</h2>
								<p className="mb-2">
									<TranslateComponent code={languageCode} text={body} />
								</p>
								<p className="mb-4 text-primary fw-bold">
									<TranslateComponent code={languageCode} text={boldText} />
								</p>
							</Col>
							<Col className="text-end d-none d-lg-block" lg={6}>
								{logo !== null && (
									<img
										src={logo}
										className="mb-4"
										alt="Company Logo"
										style={{ maxWidth: "150px" }}
									/>
								)}
							</Col>
						</Row>
						<Row className="g-4">
							{visibleCards &&
								visibleCards.map((card, index) => (
									<Col key={index} xs={6} md={4}>
										<div
											className="h-100 d-flex flex-column justify-content-start p-2 p-md-3"
											style={{
												backgroundColor: card.colour
													? hexToRgba(card.colour, 0.2) // 0.5 for 50% opacity
													: "rgba(248, 249, 250, 0.2)",
												borderRadius: "8px",
												color: "#000000",
											}}
										>
											{card.icon && (
												<div className="mb-3">
													<img
														className="d-md-none"
														src={card.icon}
														alt=""
														style={{ maxWidth: "30px" }}
													/>
													<img
														className="d-none d-md-block"
														src={card.icon}
														alt=""
														style={{ maxWidth: "50px" }}
													/>
												</div>
											)}

											{card.heading && card.heading.title && (
												<h5
													className="mb-2 fs-4 d-none d-md-block outfit-bold"
													style={{ color: card.colour }}
												>
													{card.heading.url ? (
														<a
															href={card.heading.url}
															target={card.heading.target || "_self"}
															rel="noopener noreferrer"
															className="text-decoration-none outfit-bold"
															style={{ color: card.colour }}
														>
															<TranslateComponent
																code={languageCode}
																text={card.heading.title}
															/>
														</a>
													) : (
														<TranslateComponent
															code={languageCode}
															text={card.heading.title}
														/>
													)}
												</h5>
											)}
											{card.heading && card.heading.title && (
												<h5
													className="mb-2 fs-6 d-md-none outfit-bold"
													style={{ color: card.colour }}
												>
													{card.heading.url ? (
														<a
															href={card.heading.url}
															target={card.heading.target || "_self"}
															rel="noopener noreferrer"
															className="text-decoration-none outfit-bold"
															style={{ color: card.colour }}
														>
															<TranslateComponent
																code={languageCode}
																text={card.heading.title}
															/>
														</a>
													) : (
														<TranslateComponent
															code={languageCode}
															text={card.heading.title}
														/>
													)}
												</h5>
											)}

											{card.body && (
												<p style={{ fontSize: "0.9rem", color: "#555555" }}>
													<TranslateComponent
														code={languageCode}
														text={card.body}
													/>
												</p>
											)}
										</div>
									</Col>
								))}
						</Row>
						{showViewAllButton && (
							<div className="text-center mt-5">
								<OnclickButton
									width="w-100 w-md-auto"
									colour=""
									text={
										<TranslateComponent code={languageCode} text={"View All"} />
									}
									isOutline={true}
									onClick={handleViewAll}
								/>
							</div>
						)}{" "}
					</Col>
				</Row>
			</Container>
			<img style={graphicStyles} src={BGImage}></img>
		</section>
	);
};

const graphicStyles = {
	position: "absolute",
	bottom: "-5vw",
	right: "-15vw",
	zIndex: -1,

	width: "35vw",
};

export default CardLayoutSection;
